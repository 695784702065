@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
#Midterm,
#Final {
  margin: auto;
  min-width: 500px;
  max-width: 70%;
  padding-bottom: 25px;
}

#contact {
  margin: auto;
  padding: 10px;
  min-width: 500px;
  max-width: 70%;
  padding-top: 100px;
}
.home {
  margin: auto;
  padding: 10px;
  min-width: 500px;
  max-width: 70%;
}
body {
  margin-top: 115px;
  font-family: "Montserrat", sans-serif;
  margin-left: 15%;
  max-width: 70%;
  /* font-size: clamp(10px, 4vw, 22px); */
  min-width: 500px;
}
#right {
  float: right;
  text-align: right;
}
#opener {
  text-align: center;
}
.opening {
  margin: auto;
  /* padding-top: 25px; */
  /* background: linear-gradient(
    0deg,
    rgba(156, 101, 110, 1) 0%,
    rgba(148, 162, 183, 1) 100%
  ); */
  /* border: 3px solid black; */
  min-width: 500px;
  max-width: 70%;
  max-height: 720px;
}
a {
  text-decoration: none;
}
a:hover {
  color: salmon;
}
.column {
  float: left;
  width: 50%;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
#companyName {
  font-size: 25px;
}

#email {
  height: 30px;
  width: 35px;
}
.schema {
  height: auto;
  width: 75%;
  display: block;
  margin: 0 auto;
}
/*#edu {
  width: auto;
  display: flex;
  align-items: end;
}*/

.carousel {
  height: 80%;
  align-content: center;
  max-height: 800px;
  display: flex;
  margin: auto;
  object-fit: contain;
  padding-top: 25px;
  padding-bottom: 25px;
}

.app-header {
  border-bottom: 3px solid;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 70%;
  min-width: 500px;
  background-color: white;
  z-index: 10;
  height: 100px;
  font-size-adjust: inherit;
}
@media screen and(max-width : 700px)
{
  .app-header{
    background-color: green;
    height: 300px;
  }
}

#nav {
  position: sticky;
  border: solid 1px;
  background-color: grey;
  text-align: center;
}
#Midterm {
  border-bottom: 3px black solid;
}
#col2,
.col2 {
  float: right;
}
.app h1 {
  text-align: center;
}
.navbar > #navLinks {
  display: flex;
  justify-content: space-between;
  width: 100%;
  
}

.carousel-control-prev-icon {
  background-image: url("https://www.clipartmax.com/png/middle/5-50817_transparent-arrow-left-clip-art-at-clker-com-vector-left-arrow-no.png");
}
.carousel-control-next-icon {
  background-image: url("https://www.clipartmax.com/png/middle/5-50817_transparent-arrow-left-clip-art-at-clker-com-vector-left-arrow-no.png");
  transform: rotate(180deg);
}
.heading {
  position: sticky;
  /*Adjust the height of resume and portfolio heading*/
  top: 100px;
  background-color: white;
  border-bottom: 1px solid black;

}
#resume,
.Portfolio {
  /* background-color: #c5c0bc; */
  padding-top: 75px;
  padding-bottom: 50px;
}
#contactInformation {
  /* padding-top: 105px; */
  padding-bottom: 25px;
  /* background-color: green; */
}

.top {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 50px;
  width: 32px;
  height: 32px;
  transform: rotate(90deg);
}

